<template>
  <div class="login-wrap">
    <div class="i-login-back-head">
      <div class="i-back-head-img"></div>
      <span class="i-back-head-text">忘记密码</span>
      <div class="i-back-head-right-text"></div>
    </div>
    <div class="login-content">
        <div class="ant-row ant-form-item">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <div class="account-input">
                  <input
                    v-model="phone"
                    class="ant-input i-input islide-input"
                    placeholder="手机号"
                    type="text" auto-complete="off"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <div style="position: relative">
                  <input
                    v-model="smscode"
                    class="ant-input i-input"
                    placeholder="验证码"
                    maxlength="10"
                    type="text" auto-complete="off"/>
                  <button
                    @click="sendSmsCode"
                    type="button"
                    class="ant-btn ant-btn-link"
                    style="position: absolute;right: 0px;top: 0px;height: 44px;"
                    :disabled="downCount > 0">
                    <span v-if="downCount > 0">{{downCount}}s 后重发</span>
                    <span v-else>获取验证码</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-readonly ant-input-password i-input">
                  <input
                    v-model="newpassword"
                    placeholder="新密码"
                    type="password"
                    class="ant-input" autocomplete="new-password"/>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-readonly ant-input-password i-input">
                  <input
                    v-model="repassword"
                    autocomplete="off"
                    placeholder="确认密码"
                    type="password"
                    class="ant-input" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item" style="padding-top: 28px">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <div class="flex-between">
                  <button
                    @click="forgetPassword"
                    class="ant-btn i-button button-red"
                    style="width: 100%">
                    <span>提 交</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "forgetPassword",
  data() {
    return {
      phone: '',
      smscode: '',
      newpassword: '',
      repassword: '',
      downCount: 0,
      smstype: 3
    }
  },
  methods: {
    sendSmsCode(){
      this.$http.post('/api/sendSmsCode',{phone: this.phone,smstype: this.smstype}).then(()=>{
        this.$Message.success("验证码发送成功");
        clearTimeout(this.downCountTimeOut);
        this.downCount = 60;
        this.startDownCount();
      });
    },
    startDownCount(){
      this.downCountTimeOut = setTimeout(() => {
         if(this.downCount > 0){
            this.downCount --;
            this.startDownCount();
         }
      }, 1000);
    },
    forgetPassword(){
      this.$http.post('/api/forgetPassword',{username: this.phone,smscode: this.smscode,smstype: this.smstype,newpassword: this.newpassword,repassword: this.repassword}).then(()=>{
        this.$Message.success("新密码设置成功");
        this.$router.push({name: 'login'});
      });
    }
  },
};
</script>
<style scoped>
.forgetPassword {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>